// @js-import "@common/common-dependencies.js"
// @js-import "@shared/height.js"

ComponentsManager.register('list_of_tiles', function () {
    var ScreenSize = rwd.ScreenSize,
        HeightUtil = rwd.HeightUtil;

    var privateMethod = {
        bemElements: {
            header: '.list_of_tiles__header',
            elements: '.list_of_tiles__elements',
            swiper: '.list_of_tiles__swiper',
            swiperScroller: '.list_of_tiles__swiperScroller',
            anchor: '.list_of_tiles__anchor'
        },
        classes: {
            isActive: 'isActive',
            isModal: 'modal',
            isSynced: 'isSynced',
        },
        syncHeight: function ($componentElement) {
            return {
                syncHeightItemsForMobile: privateMethod.syncHeightItemsForMobile($componentElement),
            };
        },
        componentMode: function (componentType, $componentElement) {
            return $componentElement.find('.' + componentType).length > 0 ? true : false;
        },
        syncHeightItemsForMobile: function ($componentElement) {
            var swiper = $componentElement.find(this.bemElements.swiper),
                swiperScroller = $componentElement.find(this.bemElements.swiperScroller),
                isSynced = this.classes.isSynced;
            if (privateMethod.componentMode('swipeable_list', $componentElement)) {
                return deviceUtils.isMobile
                    ? HeightUtil.resetAndSyncHeight(swiper, function () {
                        swiperScroller.addClass(isSynced);
                    })
                    : (HeightUtil.resetHeight(swiper) & swiperScroller.removeClass(isSynced));
            }
        }
    };

    return {
        init: function (componentId, $componentElement) {
            var _this = privateMethod;
            _this.syncHeight($componentElement);

            function tilesHeightSync() {
                $componentElement.removeClass("synchronized");
                $componentElement.find(".component").css("height", "100%");
                $componentElement.addClass("synchronized");
            }
            $(document).onPage(ScreenSize.RESIZE_EVENT_NAME, function () {
                tilesHeightSync();
            });
            tilesHeightSync();

            $(window).onPage("Tabs:openActiveItem", function (_, itemNode, isActive) {
                if (itemNode.hasClass(isActive)) {
                    _this.syncHeight($componentElement);
                }
            });
            $(window).onPage(ScreenSize.SIZE_CHANGE_EVENT_NAME, function () {
                _this.syncHeight($componentElement);
            });
            $(document).onPage(URLParameterManager.URL_CHANGE_EVENT, function (event) {
                _this.syncHeight($componentElement);
            });
        }
    }
});
